import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <main className="app">
      <header className="app-header" title="Lynx234" alt="Lynx234">
        <img src={logo} className="app-logo" alt="Lynx234 logo" title="Lynx234 Logo" />
        <nav id="navId">
          <a
            className="app-link"
            href="http://lynx234.com"
            target="_blank"
            title="Lynx234"
            alt="Lynx234"
            rel="noopener noreferrer"
          >
          <h1 alt="Lynx234" title="Lynx234">
            Lynx234
          </h1>
          </a>
          <a
            className="app-link"
            href="https://soundcloud.com/lynx234"
            target="_blank"
            title="Lynx234 Sound Cloud"
            alt="Lynx234 Sound Cloud"
            rel="noopener noreferrer"
          >
            Listen
          </a>
          <a
            className="app-link"
            href="https://wavox.bandcamp.com/"
            target="_blank"
            title="Lynx234 - Acid Lake EP"
            alt="Lynx234 Acid Lake EP"
            rel="noopener noreferrer"
          >
            Lynx234 – Acid Lake EP (2019)
          </a>
          <a
            className="app-link"
            href="mailto:lynx@lynx234.com"
            target="_blank"
            title="email lynx@lynx234.com"
            alt="email lynx@lynx234.com"
            rel="noopener noreferrer"
          >
            lynx@lynx234.com
          </a>
        </nav>
      </header>
    </main>
  );
}

export default App;